import { render, staticRenderFns } from "./foreignDetail.vue?vue&type=template&id=04781c68&scoped=true&"
import script from "./foreignDetail.vue?vue&type=script&lang=js&"
export * from "./foreignDetail.vue?vue&type=script&lang=js&"
import style0 from "./foreignDetail.vue?vue&type=style&index=0&id=04781c68&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04781c68",
  null
  
)

export default component.exports