<template>
  <div class="domesticDetail">
    <Icon type="ios-redo" class="goback" @click="back" />

    <div class="title">基本信息</div>

    <div>
      <!-- <div class="table-title">{{ jwz.zqqc }}</div> -->
      <cTableItem
        :titleArr="['实际融资人', '本次发行规模(亿元)']"
        :contentArr="[jwz.actualFinanciersFull, jwz.distributionScale]"
      ></cTableItem>
      <cTableItem
        :titleArr="['实际融资人简称', '总发行规模(亿元)']"
        :contentArr="[jwz.actualFinanciersAbb, jwz.finalDistributionScale]"
      ></cTableItem>
      <cTableItem
        :titleArr="['所属集团股票代码', '未偿余额(亿元)']"
        :contentArr="[jwz.groupStockCode, jwz.outstandingBalance]"
      ></cTableItem>
      <cTableItem
        :titleArr="['所属集团', '币种']"
        :contentArr="[jwz.groupEnterpriseFull, jwz.distributionCurrency]"
      ></cTableItem>
      <cTableItem
        :titleArr="['所属集团简称', '发行价格']"
        :contentArr="[jwz.groupEnterpriseAbb, jwz.distributionPrice]"
      ></cTableItem>
      <cTableItem
        :titleArr="['行业', '票面利率(%)']"
        :contentArr="[jwz.zstIndustryName, jwz.couponRate]"
      ></cTableItem>
      <cTableItem
        :titleArr="['债券类型', '发行收益率(%)']"
        :contentArr="[jwz.bondType, jwz.distributionYieldRate]"
      ></cTableItem>
      <cTableItem
        :titleArr="['发行结构', '利率类型']"
        :contentArr="[jwz.distributionStructure, jwz.interestrateType]"
      ></cTableItem>
      <cTableItem
        :titleArr="['增信方式', '融资日期']"
        :contentArr="[jwz.creditPromotionWay, jwz.financialDate]"
      ></cTableItem>
      <cTableItem
        :titleArr="['担保人', '起息日期']"
        :contentArr="[jwz.guarantor, this.getDateFmt(jwz.valueDate)]"
      ></cTableItem>
      <cTableItem
        :titleArr="['投资级别', '到期日期']"
        :contentArr="[jwz.investmentGrade, jwz.dueDate]"
      ></cTableItem>
      <cTableItem
        :titleArr="['主体评级', '定价日期']"
        :contentArr="[jwz.creditBodyGrade, this.getDateFmt(jwz.pricingDueDate)]"
      ></cTableItem>
      <cTableItem
        :titleArr="['募集资金用途', '债券评级']"
        :contentArr="[jwz.financingCapitalUse, jwz.debtRating]"
      ></cTableItem>
      <cTableItem
        :titleArr="['上市交易所', 'ISIN编码']"
        :contentArr="[jwz.bondListingExchange, jwz.isin]"
      ></cTableItem>
      <cTableItem
        :titleArr="['是否备证', '是否含权']"
        :contentArr="[jwz.isSblc, jwz.isCumRights]"
      ></cTableItem>
      <cTableItem
        :titleArr="['备证机构', '含权类型']"
        :contentArr="[jwz.sblcOrganization, jwz.cumRightsType]"
      ></cTableItem>
      <cTableItem
        :titleArr="['维好协议人', '行权日期']"
        :contentArr="[jwz.keepwellAgreementPeople, jwz.exerciseDate]"
      ></cTableItem>

      <cTableItem
        :titleArr="['性质', '行权价格']"
        :contentArr="[jwz.property, jwz.executivePrice]"
      ></cTableItem>
      <cTableItem
        :titleArr="['增发记录', '最低售价']"
        :contentArr="[jwz.rightsRecord, jwz.minprice]"
      ></cTableItem>
      <cTableItem
        :titleArr="['增发编号', '付息频率']"
        :contentArr="[jwz.creditEnhancementNum, jwz.interestDisbursementsRate]"
      ></cTableItem>
      <cTableItem
        :titleArr="['偿债顺序', '利息计算类型']"
        :contentArr="[jwz.repaymentOrder, jwz.countType]"
      ></cTableItem>
      <cTableItem
        :titleArr="['是否通货膨胀调节', '是否违约']"
        :contentArr="[jwz.isInflationAdjustment, jwz.isDefault==1?'是':jwz.isDefault === ''?'--':'否']"
      ></cTableItem>
      <cTableItem
        :titleArr="['承销商', ' ']"
        :contentArr="[jwz.underwriter, ' ']"
      ></cTableItem>
      <!-- <div>
        <div class="line-title">备注</div>
        <div class="line-content" style="width: 1243px">{{ jwz.bz }}</div>
      </div> -->
      <!-- <div class="more" @click="getMore" v-if="!isMore">点击展开更多</div> -->
    </div>
    <!-- <div v-if="isMore">
      <cTableItem
        :titleArr="['是否担保', '债券形式']"
        :contentArr="[jwz.sfdb, jwz.zqxs]"
      ></cTableItem>
      <cTableItem
        :titleArr="['选择权', '用途']"
        :contentArr="[jwz.xzq, jwz.yt]"
      ></cTableItem>
      <cTableItem
        :titleArr="['是否担保', '担保人']"
        :contentArr="[jwz.sfdb, jwz.dbr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['担保方式', '担保金额（亿元）']"
        :contentArr="[jwz.dbfs, jwz.dbje]"
      ></cTableItem>
      <cTableItem
        :titleArr="['实际担保金额（亿元）', '抵押物']"
        :contentArr="[jwz.sjdbje, jwz.dyw]"
      ></cTableItem>
      <cTableItem :titleArr="['质押物']" :contentArr="[jwz.zyw]"></cTableItem>
    </div> -->
    <!-- 临时修改start -->
    <!-- <div class="title">相关公告</div>
    <div :key="index" v-for="(item, index) in jwz.xgggList">
      <div
        class="noticeBox"
        @click="open(item2)"
        :key="index2"
        v-for="(item2, index2) in item.urlList"
      >
        <div class="arrow"></div>
        <div class="sign">发行公告</div>
        <div class="content">
          <Tooltip
            :content="item.ggbt"
            max-width="1200"
            style="width: 100%"
            word-wrap
            theme="light"
            placement="top"
            >{{ item.ggbt }}</Tooltip
          >
        </div>
        <div class="time">{{ item.gmtCreate }}</div>
      </div>
    </div> -->
    <!-- 结束 -->
  </div>
</template>

<script>
import { foreign_debt_details } from "@/api/financial/debt";
import cTableItem from "@/components/cTableItem";
import { returnFloat } from "@/utils";
import util from "../../utils/utils2";

export default {
  name: "foreignDetail",
  data() {
    return {
      isMore: false,
      jwz: {},
      xgggList: [],
    };
  },
  components: {
    cTableItem,
  },
  mounted() {
    this._get();
  },
  methods: {
    _get() {
      foreign_debt_details({
        id: this.$route.query.id,
      }).then((data) => {
        this.jwz = data;
      });
    },
    getMore() {
      this.isMore = true;
    },
    back() {
      this.$router.go(-1);
    },
    open(url) {
      window.open(url);
    },
    getDateFmt(value) {
      return util.formatDateFmt(new Date(value), "yyyy-MM-dd");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";

.domesticDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  position: relative;
  .goback {
    position: absolute;
    // top: 20px;
    // right: 20px;
    top: 0;
    color: #00b6ff;
    font-size: 36px;
    transform: rotateY(180deg);
    cursor: pointer;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: rgba(21, 98, 214, 1);
    margin: 20px 0;
    border-left: 4px solid #1562d6;
    height: 18px;
    line-height: 18px;
    padding-left: 10px;
  }

  div.more {
    height: 40px;
    background: rgba(250, 250, 253, 1);
    color: #00b6ff;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }

  div {
    font-size: 14px;
    color: rgba(51, 51, 51, 1);

    div.table-title {
      @include flex(flex-start);
      padding-left: 20px;
      background: rgba(231, 240, 252, 1);
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      border: 1px solid rgba(214, 215, 217, 1);
    }
    .table-title2 {
      // @include flex(flex-start);
      justify-content: start;
      padding-left: 20px;
      // background: rgba(231, 240, 252, 1);
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      border: 1px solid rgba(214, 215, 217, 1);
      border-top: none;
    }

    div {
      @include flex;
      height: 40px;
      line-height: 40px;

      .line-title:nth-of-type(1) {
        border-left: 1px solid rgba(214, 215, 217, 1);
      }

      .line-title {
        background: rgba(250, 250, 253, 1);
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
        word-break: keep-all;
      }

      .topline {
        border-top: 1px solid rgba(214, 215, 217, 1);
        border-bottom: none;
      }

      .line-content {
        background: #fff;
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
      }
    }
  }

  .noticeBox {
    border-bottom: 1px dashed rgba(214, 215, 217, 1);
    height: 44px;
    width: 100%;
    line-height: 44px;
    @include flex(flex-start);
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: rgba(51, 51, 51, 1);

    .arrow {
      width: 0px;
      height: 0px;
      border-left: 8px solid #aaa;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    .sign {
      width: 60px;
      height: 24px;
      white-space: nowrap;
      background: rgba(246, 250, 255, 1);
      border-radius: 2px;
      border: 1px solid rgba(21, 98, 214, 1);
      color: #1562d6;
      line-height: 24px;
      margin-right: 10px;
    }

    .content {
      flex: 2;
      justify-content: flex-start;
      overflow: hidden;
      white-space: nowrap;
    }

    .time {
      font-size: 14px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
